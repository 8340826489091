import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';

import './Works.css'

import workData from '../../data/workData'
import { lightBoxImg, works } from './../../data/Works'

const NewWorks = () => {
    const [products, setProducts] = useState([...workData])
    const [keyword, setKeyword] = useState("")
    const [currentCategory, setCurrentCategory] = useState("")
    const [currentLightBoxData, setCurrentLightBoxData] = useState([])

    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [catIsOpen, setCatIsOpen] = useState(false)

    useEffect(() => {
        const results = workData.filter(product => product.name.toLowerCase().includes(keyword.toLowerCase()))
        setProducts(results)
    }, [keyword])

    const handleChange = (e) => {
        setKeyword(e.target.value)
    }
    const handleSubmit = (item) => {
        setCurrentCategory(item.category)
        // setCurrentLightBoxData([...currentLightBoxData, 'Saddam'])
        works.filter(i => i.category === item.category).map(img => setCurrentLightBoxData([...currentLightBoxData, img.img]))
        console.log(currentLightBoxData)

    }

    return (
        <Container>
            <Row className="mt-4">
                <Col md={12}>
                    <div class="btn-group">
                        <input
                            id="search"
                            onChange={handleChange}
                            placeholder="Choose the Category..."
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            // aria-expanded="false"
                            autoComplete="off"
                            value={currentCategory}
                        />
                        <div class="dropdown-menu">
                            {
                                products.map(item => (
                                    // <a class="dropdown-item" onClick={() => handleSubmit(item)}>  {item.name} </a>
                                    <li class="dropdown-item" onClick={() => handleSubmit(item)}>  {item.name} </li>
                                ))
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: '4rem' }} className="no-gutters">

                {
                    currentCategory === 'all' || currentCategory === '' ? works.map(img => (
                        <Col style={{ cursor: 'pointer' }} onClick={() => setIsOpen(true)} xs md={3} lg={3} className="image-hover image-enlarge">
                            <div className="client-box text-center">
                                <img style={{ width: '100%', height: '100%' }} src={img.img} alt="advertising-icon" />
                            </div>
                        </Col>
                    )) :
                        works.filter(item => item.category === currentCategory).map(img => (

                            <Col onClick={() => setCatIsOpen(true)} xs md={3} lg={3} className="image-hover image-enlarge">
                                <div className="client-box text-center">
                                    <img style={{ width: '100%', height: '100%' }} src={img.img} alt="advertising-icon" />
                                </div>
                            </Col>
                        ))
                }
            </Row>

            {isOpen && (
                <Lightbox
                    mainSrc={lightBoxImg[photoIndex]}
                    nextSrc={lightBoxImg[(photoIndex + 1) % lightBoxImg.length]}
                    prevSrc={lightBoxImg[(photoIndex + lightBoxImg.length - 1) % lightBoxImg.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + lightBoxImg.length - 1) % lightBoxImg.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % lightBoxImg.length)
                    }
                />
            )}

            {catIsOpen && (
                <Lightbox
                    mainSrc={currentLightBoxData[photoIndex]}
                    nextSrc={currentLightBoxData[(photoIndex + 1) % currentLightBoxData.length]}
                    prevSrc={currentLightBoxData[(photoIndex + currentLightBoxData.length - 1) % currentLightBoxData.length]}
                    onCloseRequest={() => setCatIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + currentLightBoxData.length - 1) % currentLightBoxData.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % currentLightBoxData.length)
                    }
                />
            )}
        </Container>
    );
};

export default NewWorks;