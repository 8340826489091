import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import forwardIcon from '../../icons/Forward Icon_Yellow.svg'

import './Home.css'

const Home = () => {
    return (
        <Container id="home">
            <Row>
                <Col md={12}>
                    <div className="mt-4">
                        <h1 id="hero-text">
                            We are not just <br />
                            <span style={{ color: '#FFC10E' }}> ordinary</span>. <br />
                        Let’s put <span style={{ color: '#FFC10E' }}> Extra </span> <br />
                        before that...
                    </h1>
                    </div>
                    <p id="hero-p">
                        Nobody hires Studio Looper to create something ordinary. When Looper engage, some extra expectation rises inside the minds. Our goal is to continually create value for our clients, which is made easier when we’re given the opportunity to challenge conventional thinking. Studio Looper provides Creative Designs for print and digital media for a wide variety of clients and helping businesses create brands of substance and style.
                    </p>
                    {/* <div className="my-5">
                        <h6 id="hero-h6">
                            Let’s See what’s extra with Looper <img className="hero-img" src={forwardIcon} alt="" />
                        </h6>
                    </div> */}
                </Col>
            </Row>
        </Container>
    );
};
export default Home;