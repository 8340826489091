import PrintAd1 from './../../src/images/works/01-print-ad/01-print-ad.png'
import PrintAd2 from './../../src/images/works/01-print-ad/02-print-ad.png'
import PrintAd3 from './../../src/images/works/01-print-ad/03-print-ad.png'
import PrintAd4 from './../../src/images/works/01-print-ad/04-print-ad.png'
import PrintAd5 from './../../src/images/works/01-print-ad/05-print-ad.png'
import PrintAd6 from './../../src/images/works/01-print-ad/06-print-ad.png'
import PrintAd7 from './../../src/images/works/01-print-ad/07-print-ad.png'

// const workPrintAd = [PrintAd1, PrintAd2, PrintAd3, PrintAd4, PrintAd5, PrintAd6, PrintAd7]
const workPrintAd = [PrintAd1, PrintAd2, PrintAd3, PrintAd4, PrintAd5, PrintAd6, PrintAd7]

export default workPrintAd