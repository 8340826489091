import CorporateBranding1 from './../images/works/Corporate Branding/Business Card-01.jpg'
import CorporateBranding2 from './../images/works/Corporate Branding/Business Card-02.jpg'
import CorporateBranding3 from './../images/works/Corporate Branding/Business-Card-Design_ChooMayKuen.jpg'
import CorporateBranding4 from './../images/works/Corporate Branding/Business-Card-Design_RosnahHassan.jpg'
import CorporateBranding5 from './../images/works/Corporate Branding/BusinessCard_03_01.jpg'
import CorporateBranding6 from './../images/works/Corporate Branding/Envelop_01.jpg'
import CorporateBranding7 from './../images/works/Corporate Branding/GBL_Stationary_Set_2.jpg'
import CorporateBranding8 from './../images/works/Corporate Branding/Letterhead_01.jpg'
import CorporateBranding9 from './../images/works/Corporate Branding/Rahbar Branding.jpg'
import CorporateBranding10 from './../images/works/Corporate Branding/Rahbar Logo Final.jpg'
import CorporateBranding11 from './../images/works/Corporate Branding/Shaadibazar_Logo_Sample_01.jpg'
import CorporateBranding12 from './../images/works/Corporate Branding/Stationery-Mockup-LDMR.jpg'
import CorporateBranding13 from './../images/works/Corporate Branding/Stationery-Mockup-Shaadi Bazaar.jpg'

const CorporateBranding = [CorporateBranding1, CorporateBranding2, CorporateBranding3, CorporateBranding4, CorporateBranding5, CorporateBranding6, CorporateBranding7, CorporateBranding8, CorporateBranding9, CorporateBranding10, CorporateBranding11, CorporateBranding12, CorporateBranding13]

export default CorporateBranding