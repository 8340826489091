const workData = [
    // { 'w_id': 1, 'name': 'Advertising' },
    // { 'w_id': 2, 'name': 'Corporate Branding' },
    // { 'w_id': 3, 'name': 'Publication Design' },
    // { 'w_id': 4, 'name': 'Calender Design' },
    // { 'w_id': 5, 'name': 'Digital Content Design' },
    // { 'w_id': 6, 'name': 'Fashion Portfolio' },
    // { 'w_id': 7, 'name': 'Typography' },
    // { 'w_id': 8, 'name': 'Others' }
    // { 'w_id': 1, 'name': 'Calendar' },
    // { 'w_id': 1, 'name': 'Clients Logo' },
    { 'w_id': 0, 'name': 'All Works', 'category': 'all' },
    { 'w_id': 1, 'name': 'Desk Calendar', 'category': 'ad' },
    { 'w_id': 2, 'name': 'Print Ad', 'category': 'bd' },
    { 'w_id': 3, 'name': 'Wall Calendar', 'category': 'cb' },
    { 'w_id': 4, 'name': 'Advertising', 'category': 'dc' },
    { 'w_id': 5, 'name': 'Corporate Branding', 'category': 'dp' },
    { 'w_id': 6, 'name': 'Digital Promotions', 'category': 'fp' },
    { 'w_id': 7, 'name': 'Fashion Portfolio', 'category': 'id' },
    { 'w_id': 8, 'name': 'Brochure Design', 'category': 'md' },
    { 'w_id': 9, 'name': 'Logo Design', 'category': 'od' },
    { 'w_id': 10, 'name': 'Mnemonics Design', 'category': 'ot' },
    { 'w_id': 11, 'name': 'Outdoor', 'category': 'pa' },
    { 'w_id': 12, 'name': 'Publications', 'category': 'pb' },
    { 'w_id': 13, 'name': 'Typo', 'category': 'ty' },
    { 'w_id': 14, 'name': 'Others', 'category': 'wc' }

]
export default workData