import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import cardLogo from './../../../src/logo.png'
import forwardIcon from '../../icons/Forward Icon_Yellow.svg'
import { Link } from 'react-router-dom'
import OrderNowBtn from '../ReusableComponents/OrderNowBtn'

import './ServicePackages.css'

const ServicePackages = () => {
    return (
        <Container>
            <Row style={{ marginBottom: '3rem', marginLeft: '1rem'}} className="mt-4">
                <h1>Our Packages</h1>
            </Row>
            <Row style={{ marginBottom: '4rem' }} className="mt-5">

                <Col style={{ margin: '0 auto', display: 'block' }} md={4}>
                    <Card style={{ border: '1px solid #ffc10e', width: '18rem', backgroundColor: '#000000', marginLeft: '1rem', height: '500px' }}>
                        <Card.Img className="card-logo" variant="top" src={cardLogo} />
                        <Card.Body>
                            <Card.Title className="eco-text">Economy Package</Card.Title>
                            <hr className='admin-title-line' />
                            <h6 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Logo (3 Options)</h6>
                            <h6 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Business Card (2 Options)</h6>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Letterhead (2 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Envelop (2 Options)</h5>
                        </Card.Body>
                        <OrderNowBtn />
                    </Card>
                </Col>

                <Col style={{ margin: '0 auto', display: 'block' }} md={4}>
                    <Card style={{ border: '1px solid #ffc10e', width: '18rem', backgroundColor: '#000000', marginLeft: '1rem', height: '500px' }}>
                        <Card.Img className="card-logo" variant="top" src={cardLogo} />
                        <Card.Body>
                            <Card.Title className="eco-text">Gold Package</Card.Title>
                            <hr className='admin-title-line' />
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Logo (5 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Business Card (3 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Letterhead (3 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Envelop (2 Sizes - 3 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Office materials Branding</h5>
                        </Card.Body>
                        <OrderNowBtn />
                    </Card>
                </Col>

                <Col style={{ margin: '0 auto', display: 'block' }} md={4}>
                    <Card style={{ border: '1px solid #ffc10e', width: '18rem', backgroundColor: '#000000', marginLeft: '1rem', height: '500px' }}>
                        <Card.Img className="card-logo" variant="top" src={cardLogo} />
                        <Card.Body>
                            <Card.Title className="eco-text">Premium Package</Card.Title>
                            <hr className='admin-title-line' />
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Logo (5 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Business Card (5 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Letterhead (5 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Envelop (2 Sizes - 5 Options)</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Corporate Branding</h5>
                            <h5 style={{ textAlign: 'center', fontSize: '1.125rem' }}>Basic Gift Items Branding</h5>
                        </Card.Body>
                        <OrderNowBtn />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={12}>


                    <div className="mb-5">
                        <h6 id="hero-h6">
                            Let’s See what’s extra with Looper <Link to="works"><img className="hero-img" src={forwardIcon} alt="" /></Link>
                        </h6>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default ServicePackages;