import React from 'react';

import forwardIconBlack from '../../icons/forwardIconBlack.svg'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './Package.css'

const Package = () => {
    return (
        <Container>
            <Row className="mt-4">
                <Col md={12}>
                    <h1 id="package-text">
                        <span style={{ color: '#FFC10E' }}> DOUBT </span>can only<br />
                        be removed <br />
                        by <span style={{ color: '#FFC10E' }}> ACTION. </span>
                    </h1>
                    <h4 id="johann">– Johann Wolfgang Von Goethe</h4>
                </Col>
            </Row>
            {/* <Row style={{marginTop:'1rem', marginBottom:'2rem'}}> */}
            <Row style={{ marginBottom: '4rem', marginTop:'3rem'}}>
                <Col className="mt-4" md={4}>
                    <Card style={{ backgroundColor: '#FFC10E', color: '#000', height: '33rem' }}>
                        <Card.Body>
                            <Card.Title className="package-title">Project Work</Card.Title>
                            <Card.Text id="pack-item">
                                <ul className="dashed">
                                    <li>Planned for a specific project only.</li>
                                    <li>Timeline based</li>
                                    <li>One time contract</li>
                                    <li> Delivery after final approval </li>
                                    <li> Pricing depends on the project time and volume of work. </li>
                                    <li> Project starts after 40% advance payment </li>
                                    <li> Rest of the payment has to be cleared on the day of final delivery. </li>
                                </ul>
                            </Card.Text>
                            <Card.Footer style={{ marginTop: '6.5rem' }} className="start-with-looper">Start with Looper <Link to="/order-now"> <img className="looper-icon" src={forwardIconBlack} alt="" /> </Link></Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="mt-4" md={4}>
                    <Card style={{ backgroundColor: '#FFC10E', color: '#000', height: '33rem' }}>
                        <Card.Body>
                            <Card.Title className="package-title">Retainership</Card.Title>
                            <Card.Text id="pack-item">
                                <ul className="dashed">
                                    <li>Planned for a specific time period.</li>
                                    <li>Monthly Planned Calendar</li>
                                    <li>Minimum 6 months Contract</li>
                                    <li> Delivery of monthly content 2 times in a month </li>
                                    <li> Pricing fixed for the Planned items only. For the extra items payments will be include with the monthly invoice. </li>
                                    <li> Payment has to be cleared within 2 weeks after the Bill submitted. </li>
                                    <li> The contract can’t be change or cancel in the middle of the month. </li>
                                </ul>
                            </Card.Text>
                            <Card.Footer style={{ marginTop: '3.5rem' }} className="start-with-looper">Start with Looper <Link to="/order-now"> <img className="looper-icon" src={forwardIconBlack} alt="" /> </Link></Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="mt-4" md={4}>
                    <Card id="p-card" style={{ backgroundColor: '#FFC10E', color: '#000', height: '33rem' }}>
                        <Card.Body>
                            <Card.Title className="package-title">Other</Card.Title>
                            <Card.Text id="pack-item">
                                <ul className="dashed">
                                    <li>Job wise plan.</li>
                                    <li>Contract according to the job.</li>
                                    <li>Pricing depends of the job volume.</li>
                                    <li> Delivery time has to be mention. </li>
                                    <li> Pricing depends on the job criteria. </li>
                                    <li> 40% advance has to be clear after the Primary approval for doing the job. </li>
                                    <li> Rest Payment has to be cleared at the time of final approval. </li>
                                </ul>
                            </Card.Text>
                            <Card.Footer style={{ marginTop: '8rem' }} className="start-with-looper">Start with Looper <Link to="/order-now"> <img className="looper-icon" src={forwardIconBlack} alt="" /> </Link></Card.Footer>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Package;