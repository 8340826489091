import React from 'react';
import { Link } from 'react-router-dom';

import './SocialStickyBar.css'

const StickySocialBar = () => {

    return (
        <div id="social-sidebar" className="sticky-container">
            <ul className="sticky">
                <li className="first-li" style={{ listStyle: 'none', writingMode: 'vertical-rl', height: 'auto', padding: '10px 0' }}>
                    {/* <p>Order Now</p> */}
                    
                    <p>
                    <Link to="/order-now" className="order-nav" style={{ textDecoration: 'none', color: 'white' }}>
                        Order Now
                    </Link>
                    </p>
                </li>
                <li style={{ listStyle: 'none' }}>
                    <a href="tel:01912245690" className="phone-nav" style={{ textDecoration: 'none', color: 'white' }}>
                        <i className="fas fa-phone icon"></i>
                        <p className="phone-text">Phone</p>
                    </a>
                </li>

                <li style={{ listStyle: 'none' }}>
                    <a href="https://www.facebook.com/studiolooper" target="_blank" className="phone-nav" style={{ textDecoration: 'none', color: 'white' }}>
                        <i className="fab fa-facebook icon"></i>
                        <p className="phone-text">Facebook</p>
                    </a>
                </li>

                <li style={{ listStyle: 'none' }}>
                    <a href="https://www.instagram.com/studio.looper/" target="_blank" className="phone-nav" style={{ textDecoration: 'none', color: 'white' }}>
                        <i className="fab fa-instagram icon"></i>
                        <p className="phone-text">Instagram</p>
                    </a>
                </li>
            </ul>
        </div>

    )
}


export default StickySocialBar;