import WallCalendar1 from './../images/works/03-wall-calendar/Air Asia_3_Calendar.jpg' 
import WallCalendar2 from './../images/works/03-wall-calendar/Air Asia_4_Calendar.jpg' 
import WallCalendar3 from './../images/works/03-wall-calendar/Air Asia_5_Calendar.jpg' 
import WallCalendar4 from './../images/works/03-wall-calendar/Air Asia_6_Calendar.jpg' 
import WallCalendar5 from './../images/works/03-wall-calendar/Mahindra_01.jpg' 
import WallCalendar6 from './../images/works/03-wall-calendar/W_Calendar_01.png' 
import WallCalendar7 from './../images/works/03-wall-calendar/W_Calendar_02.png' 
import WallCalendar8 from './../images/works/03-wall-calendar/W_Calendar_03.png' 
import WallCalendar9 from './../images/works/03-wall-calendar/W_Calendar_04.png' 
import WallCalendar10 from './../images/works/03-wall-calendar/W_Calendar_05.png' 
import WallCalendar11 from './../images/works/03-wall-calendar/W_Calendar_06.png' 
import WallCalendar12 from './../images/works/03-wall-calendar/W_Calendar_07.png'

const WallCalendar = [WallCalendar1, WallCalendar2, WallCalendar3, WallCalendar4, WallCalendar5, WallCalendar6, WallCalendar7, WallCalendar8, WallCalendar9, WallCalendar10, WallCalendar11, WallCalendar12]

export default WallCalendar