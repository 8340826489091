import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faStar } from '@fortawesome/free-solid-svg-icons'

import C1 from './../../images/customer-img/C1.png'

import './CustomerReview.css'

const CustomerReview = () => {
    return (
        <Container fluid id="client-review">
            <Container id="testimonial">
                <Row style={{ marginBottom: '4rem' }}>
                    <Col>
                        <Carousel className="caro">
                            <Carousel.Item>
                                <div>
                                    <div className="c-img">
                                        <img className="c-img-style" src={C1} alt="Customer Img" />
                                    </div>
                                    <div className="mt-3">
                                        <p> <strong> Ahmad H. Sabbir </strong> </p>
                                    </div>
                                    <p> Brand Artist, Studio Looper</p>
                                    <div>
                                        <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe maiores unde iusto. Suscipit exercitationem sed, labore corrupti impedit beatae minus.</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div>
                                    <div className="c-img">
                                        <img className="c-img-style" src={C1} alt="Customer Img" />
                                    </div>
                                    <div className="mt-3">
                                        <p> <strong> Ahmad H. Sabbir </strong> </p>
                                    </div>
                                    <p> Brand Artist, Studio Looper</p>
                                    <div>
                                        <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe maiores unde iusto. Suscipit exercitationem sed, labore corrupti impedit beatae minus.</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div>
                                    <div className="c-img">
                                        <img className="c-img-style" src={C1} alt="Customer Img" />
                                    </div>
                                    <div className="mt-3">
                                        <p> <strong> Ahmad H. Sabbir </strong> </p>
                                    </div>
                                    <p> Brand Artist, Studio Looper</p>
                                    <div>
                                        <p className="mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe maiores unde iusto. Suscipit exercitationem sed, labore corrupti impedit beatae minus.</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default CustomerReview;