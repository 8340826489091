import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { useAlert } from "react-alert";

import './OrderNow.css'

const OrderNow = () => {
    const alert = useAlert();
    const { register, handleSubmit, errors } = useForm();

    // const onSubmit = data => {
    //     console.log(data)
    // };

    const onSubmit = (data, e) => {

        emailjs.sendForm('gmail', 'template_ardbvm7', e.target, 'user_GfGDGhAl7v6hxLLA132Us')
            .then((result) => {
                // setAlertShow(true)
                alert.success("Form Submitted Successfully!");
                // console.log(result.text);
            }, (error) => {
                // console.log(error.text);
                alert.error("Something Wrong!");
            });
        e.target.reset()
    };

    return (
        <Container className="mt-5" id="order-now">
            <Row>
                <Col md={8}>
                    <div>
                        <div class="form-group row mb-b" style={{ marginLeft: '0px' }}>
                            <div className="col-sm-12">
                                <p style={{ fontSize: '17px', marginLeft: '0px' }}>* Please fill the form. Our appionted associate will communicate with you.</p>
                            </div>
                        </div>
                        <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
                            <div class="form-group row" style={{ marginLeft: '0px' }}>
                                <div className="col-sm-2">
                                    <label style={{ fontSize: '17px' }} for="inputName" class="col-form-label">Name: </label>
                                </div>
                                <div class="col-sm-10" style={{ marginLeft: '-40px' }}>
                                    <input ref={register({ required: true })} name="name" style={{ padding: '5px 20px' }} type="text" id="inputName" />
                                    {errors.name && <li style={{ color: '#FFC113' }}>Please enter your name</li>}
                                </div>
                            </div>

                            <div class="form-group row" style={{ marginLeft: '0px' }}>
                                <div className="col-sm-2">
                                    <label style={{ fontSize: '17px' }} for="inputEmail" class="col-form-label">Email: </label>
                                </div>
                                <div class="col-sm-10" style={{ marginLeft: '-40px' }}>
                                    <input ref={register({ required: true })} name="email" style={{ padding: '5px 20px' }} type="email" id="inputEmail" />
                                    {errors.email && <li style={{ color: '#FFC113' }}>Please enter your email</li>}
                                </div>
                            </div>

                            <div class="form-group row" style={{ marginLeft: '0px' }}>
                                <div className="col-sm-2">
                                    <label style={{ fontSize: '17px' }} for="inputPhone" class="col-form-label">Phone: </label>
                                </div>
                                <div class="col-sm-10" style={{ marginLeft: '-40px' }}>
                                    <input ref={register({ required: true })} name="phone" style={{ padding: '5px 20px' }} type="text" id="inputPhone" />
                                    {errors.phone && <li style={{ color: '#FFC113' }}> Please enter your phone</li>}
                                </div>
                            </div>

                            <div style={{ marginLeft: '0px' }} class="form-group row">
                                <div className="col-sm-12">
                                    <hr className='form-divider' />
                                </div>
                            </div>

                            <div class="form-group row" style={{ marginLeft: '0px' }}>
                                <div className="col-sm-3">
                                    <label style={{ fontSize: '17px' }} for="inputCompanyName" class="col-form-label">Company Name: </label>
                                </div>
                                <div class="col-sm-9" style={{ marginLeft: '-40px' }}>
                                    <input ref={register({ required: true })} name="companyName" style={{ padding: '5px 20px' }} type="text" id="inputCompanyName" />
                                    {errors.companyName && <li style={{ color: '#FFC113' }}> Please enter company name</li>}
                                </div>
                            </div>

                            <div class="form-group row" style={{ marginLeft: '0px' }}>
                                <div className="col-sm-3">
                                    <label style={{ fontSize: '17px' }} for="inputProjectName" class="col-form-label">Project Name: </label>
                                </div>
                                <div class="col-sm-9" style={{ marginLeft: '-40px' }}>
                                    <input ref={register({ required: true })} name="projectName" style={{ padding: '5px 20px' }} type="text" id="inputProjectName" />
                                    {errors.projectName && <li style={{ color: '#FFC113' }}>Please enter project name</li>}
                                </div>
                            </div>

                            <div style={{ marginLeft: "-60px" }} className="form-check form-check-inline">

                                <input className="for-small form-check-input" type="checkbox" name="logo" value="Logo"
                                    ref={register({ required: true })}
                                />
                                <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Logo</label>
                            </div>
                            {errors.logo && <li style={{ color: '#FFC113' , marginLeft: '-8px'}}>Please select logo</li>}

                            <label style={{ marginLeft: '15px' }} className="control-label mt-3" htmlFor="brandIdentity">Brand Identity<br />

                                <div className="d-flex">
                                    <div className="form-check form-check-inline mt-3">

                                        <input className="for-small form-check-input" type="checkbox" name="brandIdentity" value="Package 01"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Package 01</label>
                                    </div>

                                    <div className="form-check form-check-inline mt-3">

                                        <input className="for-small form-check-input" type="checkbox" name="brandIdentity" value="Package 02"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Package 02</label>
                                    </div>

                                    <div className="form-check form-check-inline mt-3" style={{ marginRight: '0' }}>

                                        <input className="for-small form-check-input" type="checkbox" name="brandIdentity" value="Package 03"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Package 03</label>
                                    </div>

                                    <div className="form-check form-check-inline mt-3" style={{ marginRight: '0' }}>

                                        <Link className="package-detail" to="/service-packages">
                                            [Check the package detail here]
                                        </Link>
                                    </div>
                                </div>
                                {errors.brandIdentity && <li style={{ color: '#FFC113' }}>Please select Brand Identity</li>}
                            </label>

                            <div style={{ marginLeft: "-60px" }} className="form-check form-check-inline">

                                <input className="for-small form-check-input" type="checkbox" name="calendar" value="Calendar"
                                    ref={register({ required: true })}
                                />
                                <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Calendar</label>
                            </div>
                            {errors.calendar && <li style={{ color: '#FFC113' , marginLeft: '-8px'}}>Please select calendar</li>}
                            
                            <br />
                            <div style={{ marginLeft: "-60px" }} className="form-check form-check-inline mt-3">

                                <input className="for-small form-check-input" type="checkbox" name="brandMaterials" value="Brand Materials"
                                    ref={register({ required: true })}
                                />
                                <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Brand Materials</label>
                                {/* {errors.brandMaterials && <li style={{ color: 'red' }}>Please select digital content</li>} */}
                            </div>
                            {errors.calendar && <li style={{ color: '#FFC113' , marginLeft: '-8px'}}>Please select brand materials</li>}

                            <label style={{ marginLeft: '15px' }} className="control-label mt-3" htmlFor="digitalContent">Digital Content<br />

                                <div className="d-flex">
                                    <div className="form-check form-check-inline mt-3">

                                        <input className="for-small form-check-input" type="checkbox" name="digitalContent" value="Facebook"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Facebook</label>
                                    </div>

                                    <div className="form-check form-check-inline mt-3">

                                        <input className="for-small form-check-input" type="checkbox" name="digitalContent" value="YouTube"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">YouTube</label>
                                    </div>

                                    <div className="form-check form-check-inline mt-3" style={{ marginRight: '0' }}>

                                        <input className="for-small form-check-input" type="checkbox" name="digitalContent" value="Instagram"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Instagram</label>
                                    </div>

                                    <div className="form-check form-check-inline mt-3" style={{ marginRight: '0' }}>

                                        <input className="for-small form-check-input" type="checkbox" name="digitalContent" value="Others"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Others</label>
                                    </div>

                                </div>
                                {errors.digitalContent && <li style={{ color: '#FFC113' }}>Please select Digital Content</li>}
                            </label>

                            <label style={{ marginLeft: '15px' }} className="control-label mt-3" htmlFor="animation">Animation<br />
                                {/* <div className="d-flex"> */}
                                <div className="form-check form-check-inline mt-3">

                                    <input className="for-small form-check-input" type="checkbox" name="animation" value="Short (Less than 30 Sec)"
                                        ref={register({ required: true })}
                                    />
                                    <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Short (Less than 30 Sec)</label>
                                </div>
                                {/* </div> */}
                                <div className="d-flex">
                                    <div className="form-check form-check-inline mt-3" style={{ marginRight: '0' }}>

                                        <input className="for-small form-check-input" type="checkbox" name="animation" value="Medium (Less than 60 Sec)"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Medium (Less than 60 Sec)</label>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="form-check form-check-inline mt-3" style={{ marginRight: '0' }}>

                                        <input className="for-small form-check-input" type="checkbox" name="animation" value="Long (More than 60 Sec)"
                                            ref={register({ required: true })}
                                        />
                                        <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Long (More than 60 Sec)</label>
                                    </div>

                                </div>

                                {errors.animation && <li style={{ color: '#FFC113' }}>Please select Animation</li>}
                            </label>


                            <br />
                            <div style={{ marginLeft: "-60px" }} className="form-check form-check-inline mt-3">

                                <input className="for-small form-check-input" type="checkbox" name="website" value="Website"
                                    ref={register({ required: true })}
                                />
                                <label style={{ marginLeft: '10px' }} className="form-check-label label-sm">Website</label>
                            </div>
                            {errors.website && <li style={{ color: '#FFC113' , marginLeft: '-8px'}}>Please select Website</li>}

                            <br />

                            <label style={{ marginLeft: '15px' }} className="control-label mt-3" htmlFor="animation">Other<br />
                                <textarea className="mt-3" rows="2" cols="100" name="other" ref={register({ required: true })}></textarea>
                                {errors.other && <li style={{ color: '#FFC10E' }}>Please enter Other Requirements</li>} <br />
                            </label>

                            <label style={{ marginLeft: '15px' }} className="control-label" htmlFor="animation">Medium of Communication:<br /></label>

                            <div class="form-group row" style={{ marginLeft: '0px' }}>
                                <div className="col-sm-2">
                                    <label style={{ fontSize: '17px' }} for="inputCommunicationEmail" class="col-form-label">Email </label>
                                </div>
                                <div class="col-sm-5" style={{ marginLeft: '-40px' }}>
                                    <input ref={register({ required: true })} name="communicationEmail" style={{ padding: '5px 20px' }} type="email" id="inputCommunicationEmail" />
                                    {errors.communicationEmail && <li style={{ color: '#FFC10E' }}>Please enter communication email</li>}
                                </div>
                            </div>

                            <div class="form-group row mt-3" style={{ marginLeft: '0px' }}>
                                <div className="col-sm-3">
                                    <label style={{ fontSize: '17px' }} for="inputMeeting" class="col-form-label">Set A Meeting</label>
                                </div>
                                <div class="col-sm-4">
                                    <input ref={register({ required: true })} name="meeting" style={{ padding: '5px 20px', marginLeft: '-42px' }} type="date" id="inputMeeting" />
                                    {errors.meeting && <li style={{ color: '#FFC10E' }}>Please Set a Meeting</li>}
                                </div>
                            </div>

                            <div className="my-5">
                                <input id="OrderNowBtn" value="Order Now" type="submit" />
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default OrderNow