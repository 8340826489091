import ad1 from './../images/works/works/ad-1.jpg'
import bd1 from './../images/works/works/bd-1.jpg'
import cb1 from './../images/works/works/cb-1.jpg'
import dc1 from './../images/works/works/dc-1.png'
import dp1 from './../images/works/works/dp-1.jpg'
import fp1 from './../images/works/works/fp-1.jpg'
import id1 from './../images/works/works/id-1.jpg'
import md1 from './../images/works/works/md-1.jpg'
import od1 from './../images/works/works/od-1.jpg'
import ot1 from './../images/works/works/ot-1.jpg'
import pa1 from './../images/works/works/pa-1.png'
import pb1 from './../images/works/works/pb-1.jpg'
import ty1 from './../images/works/works/ty-1.jpg'
import wc1 from './../images/works/works/wc-1.jpg'

export const works = [
    { category: 'ad', img: ad1 },
    { category: 'bd', img: bd1 },
    { category: 'cb', img: cb1 },
    { category: 'dc', img: dc1 },
    { category: 'dp', img: dp1 },
    { category: 'fp', img: fp1 },
    { category: 'id', img: id1 },
    { category: 'md', img: md1 },
    { category: 'od', img: od1 },
    { category: 'ot', img: ot1 },
    { category: 'pa', img: pa1 },
    { category: 'pb', img: pb1 },
    { category: 'ty', img: ty1 },
    { category: 'wc', img: wc1 }

]

export const lightBoxImg = works.map(img => img.img)

// export default works