import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import 'react-image-lightbox/style.css';

import './Clients.css'

import clientLogo from './../../data/ClientLogo'

const Clients = () => {


    return (
        <Container id="clients">
            <Row style={{ marginBottom: '4rem' }} className="no-gutters mt-4">
                {
                    clientLogo.map(img => (
                        <Col style={{ cursor: 'pointer' }} xs md={3} lg={3}>
                            <div className="client-box text-center client-gutter">
                                <img className="img-fluid" onContextMenu={(e) => (e.preventDefault())} id="client-logo-img" src={img} alt="Clients Logo" />
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    );
};

export default Clients;