import BrochureDesign1 from './../images/works/Final Brochure Design/01.-Cover-Back.jpg'
import BrochureDesign2 from './../images/works/Final Brochure Design/02.-2-3.jpg'
import BrochureDesign3 from './../images/works/Final Brochure Design/03.-4-5.jpg'
import BrochureDesign4 from './../images/works/Final Brochure Design/04.-6-7.jpg'
import BrochureDesign5 from './../images/works/Final Brochure Design/05.-8-9.jpg'
import BrochureDesign6 from './../images/works/Final Brochure Design/06.-10-11.jpg'
import BrochureDesign7 from './../images/works/Final Brochure Design/Brochure-Envelop.jpg'

const BrochureDesign = [BrochureDesign1, BrochureDesign2, BrochureDesign3, BrochureDesign4, BrochureDesign5, BrochureDesign6, BrochureDesign7]

export default BrochureDesign