import DeskCalendar1 from './../../src/images/works/02-desk-calendar/01-d-calendar.png'
import DeskCalendar2 from './../../src/images/works/02-desk-calendar/02-d-calendar.png'
import DeskCalendar3 from './../../src/images/works/02-desk-calendar/03-d-calendar.png'
import DeskCalendar4 from './../../src/images/works/02-desk-calendar/04-d-calendar.png'
import DeskCalendar5 from './../../src/images/works/02-desk-calendar/05-d-calendar.png'
import DeskCalendar6 from './../../src/images/works/02-desk-calendar/06-d-calendar.png'
import DeskCalendar7 from './../../src/images/works/02-desk-calendar/07-d-calendar.jpg'
import DeskCalendar8 from './../../src/images/works/02-desk-calendar/08-d-calendar.png'
import DeskCalendar9 from './../../src/images/works/02-desk-calendar/09-d-calendar.jpg'
import DeskCalendar10 from './../../src/images/works/02-desk-calendar/10-d-calendar.png'
import DeskCalendar11 from './../../src/images/works/02-desk-calendar/11-d-calendar.png'
import DeskCalendar12 from './../../src/images/works/02-desk-calendar/12-d-calendar.jpg'
import DeskCalendar13 from './../../src/images/works/02-desk-calendar/13-d-calendar.jpg'
import DeskCalendar14 from './../../src/images/works/02-desk-calendar/14-d-calendar.jpg'
import DeskCalendar15 from './../../src/images/works/02-desk-calendar/15-d-calendar.jpg'
import DeskCalendar16 from './../../src/images/works/02-desk-calendar/16-d-calendar.jpg'
import DeskCalendar17 from './../../src/images/works/02-desk-calendar/17-d-calendar.jpg'
import DeskCalendar18 from './../../src/images/works/02-desk-calendar/18-d-calendar.png'
import DeskCalendar19 from './../../src/images/works/02-desk-calendar/19-d-calendar.png'

const DeskCalendar = [DeskCalendar1, DeskCalendar2, DeskCalendar3, DeskCalendar4, DeskCalendar5, DeskCalendar6, DeskCalendar7, DeskCalendar8, DeskCalendar9, DeskCalendar10, DeskCalendar11, DeskCalendar12, DeskCalendar13, DeskCalendar14, DeskCalendar15, DeskCalendar16, DeskCalendar17, DeskCalendar18, DeskCalendar19]

export default DeskCalendar