import Advertising1 from './../images/works/Advertising/10273092_846849762032019_5144619342151502578_o.jpg'
import Advertising2 from './../images/works/Advertising/21st-Press-2016_Final.jpg'
import Advertising3 from './../images/works/Advertising/4col-X-10in_Eid_Press_2015_01.rev.b.jpg'
import Advertising4 from './../images/works/Advertising/Award Press_8 col X 10 in_Final_Bangla-03.jpg'
import Advertising5 from './../images/works/Advertising/Brand-Forum-Press_9.8in-X-14in.jpg'
import Advertising6 from './../images/works/Advertising/feeling good.jpg'
import Advertising7 from './../images/works/Advertising/GP_2_Press.jpg'
import Advertising8 from './../images/works/Advertising/LBFL_26-March_Press_8-col-X-5-inch_Output.jpg'
import Advertising9 from './../images/works/Advertising/LBFL_Mother-Language-Day-2017-Press_Output.jpg'
import Advertising10 from './../images/works/Advertising/Press-Layout_02.jpg'
import Advertising11 from './../images/works/Advertising/Press_4col-X-10in_01_Rev.01.jpg'
import Advertising12 from './../images/works/Advertising/Promotional offer Press_8col X 5in_Output [Converted]-02.jpg'
import Advertising13 from './../images/works/Advertising/x2014_11_06_1_13_b.jpg.pagespeed.ic.lhkWGGJSiE.jpg'

const Advertising = [Advertising1, Advertising2, Advertising3, Advertising4, Advertising5, Advertising6, Advertising7, Advertising8, Advertising9, Advertising10, Advertising11, Advertising12, Advertising13]

export default Advertising