import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import forwardIcon from '../../icons/Forward Icon_Yellow.svg'
import iconAdvertinsing from '../../icons/Icon_Branding_Grey.svg'
import logoGraphics from '../../icons/Icon_Logo_Grey.svg'
import digitalContent from '../../icons/Icon_Digital Content_Grey.svg'
import animation from '../../icons/Icon_2D Animation_Grey.svg'
import graphicsDesign from '../../icons/Icon_Graphics_Grey.svg'
import advertinsing from '../../icons/Group 21.svg'

import black1 from './../../icons/service-black/1.svg'
import black2 from './../../icons/service-black/2.svg'
import black3 from './../../icons/service-black/3.svg'
import black4 from './../../icons/service-black/4.svg'
import black5 from './../../icons/service-black/5.svg'
import black6 from './../../icons/service-black/6.svg'

import './Service.css'

const Service = () => {
    const [isShown1, setIsShow1] = useState(false);
    const [isShown2, setIsShow2] = useState(false);
    const [isShown3, setIsShow3] = useState(false);
    const [isShown4, setIsShow4] = useState(false);
    const [isShown5, setIsShow5] = useState(false);
    const [isShown6, setIsShow6] = useState(false);

    return (
        <Container id="service">
            <Row className="mt-4">
                <Col md={12}>
                    <h1 id="service-text">
                        Don’t find
                        <span style={{ color: '#FFC10E' }}> customers</span><br />
                        for your product. <br />
                        Find<span style={{ color: '#FFC10E' }}> products </span> <br />
                        for your customers
                    </h1>
                    <h4 id="seth">– Seth Godin</h4>
                    <h6 className="" id="hero-h6">
                        Let’s See what’s extra with Looper <Link to="works"><img className="hero-img" src={forwardIcon} alt="" /></Link> 
                        {/* Let’s See what’s extra with Looper <Link to="/package"> <img className="hero-img" src={forwardIcon} alt="" /> </Link> */}
                    </h6>
                </Col>
            </Row>
            <Row className="no-gutters" style={{ marginBottom: '4rem', padding: '0 3rem', marginLeft: '0', paddingLeft: '0', marginTop: '20px' }}>
                <Link to="/package" style={{ textDecoration: 'none', color: '#fff' }}>
                    <Col
                        md={4}
                        onMouseEnter={() => setIsShow1(true)}
                        onMouseLeave={() => setIsShow1(false)}
                    >
                        <div className="advertising-box text-center">
                            {
                                !isShown1 ? <div className="advertising-item">
                                    <img style={{ width: '20%' }} src={iconAdvertinsing} alt="advertising-icon" />
                                    <p> Branding or <br /> Re-Branding </p>
                                </div> : <div className="advertising-item">
                                        <img style={{ width: '20%' }} src={black1} alt="advertising-icon" />
                                        <p> Branding or <br /> Re-Branding </p>
                                    </div>
                            }
                        </div>
                    </Col>
                </Link>

                <Link to="/package" style={{ textDecoration: 'none', color: '#fff' }}>
                    <Col
                        md={4}
                        onMouseEnter={() => setIsShow2(true)}
                        onMouseLeave={() => setIsShow2(false)}
                    >
                        <div className="advertising-box text-center">
                            {
                                !isShown2 ? <div className="advertising-item">
                                    <img style={{ width: '20%' }} src={graphicsDesign} alt="advertising-icon" />
                                    <p> Graphics Design </p>
                                </div> : <div className="advertising-item">
                                        <img style={{ width: '20%' }} src={black2} alt="advertising-icon" />
                                        <p> Graphics Design </p>
                                    </div>
                            }
                        </div>
                    </Col>
                </Link>
                <Link to="/package" style={{ textDecoration: 'none', color: '#fff' }}>
                    <Col
                        md={4}
                        onMouseEnter={() => setIsShow3(true)}
                        onMouseLeave={() => setIsShow3(false)}
                    >
                        <div className="advertising-box text-center">
                            {
                                !isShown3 ? <div className="advertising-item">
                                    <img style={{ width: '20%' }} src={logoGraphics} alt="advertising-icon" />
                                    <p> Logo Design </p>
                                </div> : <div className="advertising-item">
                                        <img style={{ width: '20%' }} src={black3} alt="advertising-icon" />
                                        <p> Logo Design </p>
                                    </div>
                            }
                        </div>
                    </Col>
                </Link>
                <Link to="/package" style={{ textDecoration: 'none', color: '#fff' }}>
                    <Col
                        md={4}
                        onMouseEnter={() => setIsShow4(true)}
                        onMouseLeave={() => setIsShow4(false)}
                    >
                        <div className="advertising-box text-center">
                            {
                                !isShown4 ? <div className="advertising-item">
                                    <img style={{ width: '20%' }} src={digitalContent} alt="advertising-icon" />
                                    <p> Digital Content  Design </p>
                                </div> : <div className="advertising-item">
                                        <img style={{ width: '20%' }} src={black4} alt="advertising-icon" />
                                        <p> Digital Content  Design </p>
                                    </div>
                            }
                        </div>
                    </Col>
                </Link>
                <Link to="/package" style={{ textDecoration: 'none', color: '#fff' }}>
                    <Col
                        md={4}
                        onMouseEnter={() => setIsShow5(true)}
                        onMouseLeave={() => setIsShow5(false)}
                    >
                        <div className="advertising-box text-center">
                            {
                                !isShown5 ? <div className="advertising-item">
                                    <img style={{ width: '20%' }} src={animation} alt="advertising-icon" />
                                    <p> 2D Animation </p>
                                </div> : <div className="advertising-item">
                                        <img style={{ width: '20%' }} src={black5} alt="advertising-icon" />
                                        <p> 2D Animation </p>
                                    </div>
                            }
                        </div>
                    </Col>
                </Link>
                <Link to="/package" style={{ textDecoration: 'none', color: '#fff' }}>
                    <Col
                        md={4}
                        onMouseEnter={() => setIsShow6(true)}
                        onMouseLeave={() => setIsShow6(false)}
                    >
                        <div className="advertising-box text-center">
                            {
                                !isShown6 ? <div className="advertising-item">
                                    <img style={{ width: '20%' }} src={advertinsing} alt="advertising-icon" />
                                    <p> Advertising </p>
                                </div> : <div className="advertising-item">
                                        <img style={{ width: '20%' }} src={black6} alt="advertising-icon" />
                                        <p> Advertising </p>
                                    </div>
                            }
                        </div>
                    </Col>
                </Link>
            </Row>
        </Container>
    );
};

export default Service;