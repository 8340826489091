import React from 'react';
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import Home from './components/Home/Home';
import Menu from './components/Menu/Menu';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Service from './components/Service/Service';
import Package from './components/Package/Package';
import Clients from './components/Client/Clients';
import Works from './components/Works/Works';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import SocialStickyBar from './components/SocialStickyBar/SocialStickyBar'
import OrderNow from './components/OrderNow/OrderNow';
import ServicePackages from './components/ServicePackages/ServicePackages';
import LiveChat from './components/LiveChat/LiveChat';
import MessengerCustomerChat from 'react-messenger-customer-chat'
import NewWorks from './components/Works/NewWorks';
import CustomerReview from './components/CustomerReview/CustomerReview';

const options = {
  timeout: 10000,
  position: positions.BOTTOM_CENTER
};

function App() {
  return (
    <Router>
      <div>
        <Menu></Menu>
        <Switch>
            
          <Route exact path="/">
            <Home />
            <ServicePackages />
            <CustomerReview />
            <MessengerCustomerChat
              pageId="108052024403278"
              appId="1066557250452757"
            />
          </Route>
          <Route path="/services">
            <Service />
          </Route>
          <Route path="/service-packages">
            <ServicePackages />
          </Route>
          <Route path="/package">
            <Package />
          </Route>
          <Route path="/clients">
            <Clients />
          </Route>
          <Route path="/works">
            <NewWorks />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Provider template={AlertTemplate} {...options}>
              <Contact />
            </Provider>
          </Route>
          <Route path="/order-now">
            <Provider template={AlertTemplate} {...options}>
              <OrderNow />
            </Provider>
          </Route>
        </Switch>
        <SocialStickyBar />
        <LiveChat />
      </div>
    </Router >
  );
}

export default App;
