import React from 'react';
import { Container, Row, Col, Carousel, Card } from 'react-bootstrap'
import './About.css'
import { Link } from 'react-router-dom';
import forwardIcon from '../../icons/Forward Icon_Yellow.svg'
import sabbir1 from '../../images/Sabbir_01.jpg'
import sabbir2 from '../../images/Sabbir_02.jpg'
import sabbir3 from '../../images/Sabbir_03.jpg'

const About = () => {
    return (
        <Container id="about">
            <Row className="my-4">
                <Col md={12}>
                    <h1 id="about-text">
                        If you want to
                        <span style={{ color: '#FFC10E' }}> understand</span><br />
                        how a lion hunts,<br />
                        don’t go to the zoo. <br />
                        Go to the <span style={{ color: '#FFC10E' }}>jungle.</span><br />
                    </h1>
                    <h4 id="jim">– Jim Stengel</h4>
                </Col>
            </Row>
            <Row style={{ marginLeft: '1rem' }} className="my-4">
                <Col className="located-p" md={12}>
                    <p> Located in Dhaka, Studio Looper provides Creative Designs for Print and Digital media for a wide variety of clients and helping businesses create brands of substance and style. </p>
                    <p> Relentless about creating premium, powerful, & compelling brands that connect, inspire, and drive to the goal. We understand small business and startups because we are one. Even though we’re small, but out experience and range of works are not that small. We always connect brands and customers in the places where people connect. </p>
                    <p> Studio Looper brings elegance and meaning to its designs, with its name representing its commitment to passion and goal to achieve the ultimate success, starting from the very beginning till end. The firm maintains a small client base to be able to deliver personalized attention to each project. Studio Looper works with clients in the automotive, nonprofit, multinational and retail sectors, as well as startups, festivals and events. </p>
                    <p> We’ve worked with some large, iconic & leading brands in the country, but our primary focus has been on creating change, growth and success for small to medium sized businesses. </p>
                </Col>
            </Row>
            <div style={{ marginLeft: '2rem' }}>
                <h1 className="title-owner">About the Owner</h1>
            </div>
            <Row style={{ marginBottom: '3rem', marginLeft: '1rem' }}>
                {/* <h1>About the Owner</h1> */}
                <Col md={8}>
                    {/* <h1 className="title-owner">About the Owner</h1> */}
                    <div className="about-p">
                        {/* <h1 className="title-owner">About the Owner</h1> */}
                        <p>
                            Studip Looper is the brainchild of none other than the vastly experienced Ahmed Hossain Sabbir, better
                            known as just Sabbir Hossain in the world of media and marketing. He has been working professionally
                            in this regard from 2010 in Bangladesh and has so far exhibited his mastery for some of the most giant
                            brands like British American Tobacco, Western Union Money Transfer, Nokia, Banglalink,
                            Grameenphone, Air Asia and many more local and foreign clientele. Be it Pre-Press offset Design or
                            Digital Content Planning &amp; Design or Outdoor Media Design, Sabbir Hossain always delivers the goods
                            and hence it comes as no surprise that the most prominent advertising agencies Bangladesh, the likes of
                            Grey and Roebuck Communications, have availed his service. His multifaceted expertise in designing and
                            visualization makes him a specialist in Corporate Branding, Publication Design, Calendar Design,
                            Advertising, Digital Promotional Material Design, BTL Branding, Fashion Portfolio Making and well, the
                            list could go on. Besides, quite fascinatingly, he has a great command in Typography and Calligraphy
                            Painting, that too both in Bangla and Arabic. Furthermore, he has been rigorously involved in Printing
                            Process, Leading Creative Team, Art Direction and needless to say as an Illustration Artist. It is difficult to
                            single out his accomplishments or prowess, but it is safe to say that after such a decade long illustrious
                            career, Sabbir Hossain is now venturing into a new realm of designing and the best of him seems yet to
                            come, from his very own Studio Looper.
                        </p>
                    </div>
                    {/* <h6 style={{ marginLeft: '0' }} id="hero-h6">
                        Let’s See what’s extra with Looper <img className="hero-img" src={forwardIcon} alt="" />
                    </h6> */}
                    <h6 style={{ marginLeft: '0' }} id="hero-h6">
                            Let’s See what’s extra with Looper <Link to="works"><img className="ab-hero-img" src={forwardIcon} alt="" /></Link>
                        </h6>
                </Col>


                <Col md={4}>
                    <div className="ml-4">
                        <Card.Img variant="top" src={sabbir2} />
                    </div>
                    {/* <Card className="ml-3"> */}

                    {/* </Card> */}

                </Col>
            </Row>
        </Container>
    );
};

export default About;