const searchData = [
    { 's_id': 1, 'name': 'Our Work', "url": "/works"},
    { 's_id': 2, 'name': 'Our Service', "url": "/services" },
    { 's_id': 3, 'name': 'Clients', "url": "/clients" },
    { 's_id': 4, 'name': 'Contact Us', "url": "/contact" },
    { 's_id': 5, 'name': 'Advertising', "url": "/works" },
    { 's_id': 6, 'name': 'Logo Design', "url": "/clients" },
    { 's_id': 7, 'name': 'Graphics Design', "url": "/services" },
    { 's_id': 8, 'name': 'Home', "url": "/" }
]
export default searchData