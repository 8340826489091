import React, { useState, useEffect } from 'react';
import './Menu.css'
import { Container, Row, Col, Navbar, Nav, Modal } from 'react-bootstrap';
import logo from '../../logo.png'
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import searchData from '../../data/SearchData'
import searchIcon from '../../icons/formSeachIcon.svg'

const Menu = () => {

    const [smShow, setSmShow] = useState(false);
    const [products, setProducts] = useState([...searchData])
    const [keyword, setKeyword] = useState("")

    useEffect(() => {
        const results = searchData.filter(product => product.name.toLowerCase().includes(keyword.toLowerCase()))
        setProducts(results)
    }, [keyword])

    const handleChange = (e) => {
        setKeyword(e.target.value)
    }
    return (
        <Container id="menu">
            <Row>
                <Col md={12}>
                    <>
                        <Navbar expand="lg">
                            <Navbar.Brand>
                                <Link style={{ color: '#fff', textDecoration: 'none' }} to="/">
                                    <img className="logo-style" src={logo} alt="loper-studio" />
                                </Link>

                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto">
                                    <Nav.Link className="menu-nav nav-custom" >
                                        <NavLink className="test-cls" exact  style={{ color: '#fff', textDecoration: 'none'}} to="/">
                                            Home
                                        </NavLink>
                                    </Nav.Link>
                                    <Nav.Link className="menu-nav nav-custom" >
                                        <NavLink  style={{ color: '#fff', textDecoration: 'none' }} to="/services">
                                            Services
                                        </NavLink>
                                    </Nav.Link>
                                    <Nav.Link className="menu-nav nav-custom">
                                        <NavLink to="/clients" style={{ textDecoration: 'none', color: '#fff' }}>
                                            Clients
                                        </NavLink>
                                    </Nav.Link>
                                    <Nav.Link className="menu-nav nav-custom">
                                        <NavLink to="/works" style={{ textDecoration: 'none', color: '#fff' }}>
                                            Works
                                        </NavLink>
                                    </Nav.Link>
                                    <Nav.Link className="menu-nav nav-custom">
                                        <NavLink to="/about" style={{ textDecoration: 'none', color: '#fff' }}>
                                            About
                                        </NavLink>
                                    </Nav.Link>
                                    <Nav.Link className="menu-nav nav-custom" >
                                        <NavLink to="/contact" style={{ textDecoration: 'none', color: '#fff' }}>
                                            Contact
                                        </NavLink>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setSmShow(true)} className="menu-nav nav-custom" style={{ color: '#fff' }} >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Nav.Link>
                                    <Modal
                                        size="sm"
                                        show={smShow}
                                        onHide={() => setSmShow(false)}
                                        aria-labelledby="example-modal-sizes-title-sm"
                                        style={{ color: '#000' }}
                                    >
                                        {/* popup modal  */}
                                        <Modal.Body>
                                            <div class="modal-btn-group">
                                                <input
                                                    id="modalSearch"
                                                    onChange={handleChange}
                                                    placeholder="Choose the Category..."

                                                />
                                                <img style={{ marginLeft: '-2rem', marginBottom: '0.2rem', width: '1.7rem' }} src={searchIcon} alt="" />
                                                {/* suggestions  */}
                                                <div class="">
                                                    <ul>
                                                        {
                                                            // products.map(item => (
                                                            //     <li>
                                                            //         <Link to={item.url}>
                                                            //             {item.name}
                                                            //         </Link>
                                                            //     </li>
                                                            // ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </>
                </Col>
            </Row>
        </Container>
    );
};

export default Menu;