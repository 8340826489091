import MnemonicsDesign1 from './../images/works/Mnemonics Design/ACI 25yrs Campaign Mnemonic-03.jpg'
import MnemonicsDesign2 from './../images/works/Mnemonics Design/Boshonto-Backdrop_16ftX10ft.jpg'
import MnemonicsDesign3 from './../images/works/Mnemonics Design/Danglar_01.jpg'
import MnemonicsDesign4 from './../images/works/Mnemonics Design/Eid Mnemonics-05.jpg'
import MnemonicsDesign5 from './../images/works/Mnemonics Design/Mnemonic.jpg'
import MnemonicsDesign6 from './../images/works/Mnemonics Design/Mnemonics.jpg'
import MnemonicsDesign7 from './../images/works/Mnemonics Design/Womens-Day-Mnemonic_01.jpg'

const MnemonicsDesign = [MnemonicsDesign1, MnemonicsDesign2, MnemonicsDesign3, MnemonicsDesign4, MnemonicsDesign5, MnemonicsDesign6, MnemonicsDesign7]

export default MnemonicsDesign