import React from 'react'
import { Link } from 'react-router-dom'

import './OrderNowBtn.css'

const OrderNowBtn = ()=> {
    return (
        <div style={{margin: '0 auto'}}>
            <Link to="order-now">
                <button className="btn mb-5 text-center my-order">Order Now</button>
            </Link>
        </div>
    )
}

export default OrderNowBtn