import DigitalPromotions1 from './../images/works/Digital Promotions/27.07_Post_01.jpg'
import DigitalPromotions2 from './../images/works/Digital Promotions/48375389_1672565639513929_8625599635353763840_o.png'
import DigitalPromotions3 from './../images/works/Digital Promotions/54524100_587105941776026_3208257127487373312_o.jpg'
import DigitalPromotions4 from './../images/works/Digital Promotions/54524417_587104311776189_2637942091132436480_o.jpg'
import DigitalPromotions5 from './../images/works/Digital Promotions/54727287_1253962351419836_8749173416487026688_o.jpg'
import DigitalPromotions6 from './../images/works/Digital Promotions/54728425_587108031775817_2878639699147096064_o.jpg'
import DigitalPromotions7 from './../images/works/Digital Promotions/54798940_587107505109203_4068101720356421632_o.jpg'
import DigitalPromotions8 from './../images/works/Digital Promotions/55483121_587106471775973_5580254865126326272_o.jpg'
import DigitalPromotions9 from './../images/works/Digital Promotions/55698505_586604541826166_5599898215882162176_o.jpg'
import DigitalPromotions10 from './../images/works/Digital Promotions/55837779_590686261417994_1531380682561945600_o.jpg'
import DigitalPromotions11 from './../images/works/Digital Promotions/59330249_609337592886194_7787880660999340032_o.jpg'
import DigitalPromotions12 from './../images/works/Digital Promotions/60015568_1288906744592063_641143329148370944_o.png'
import DigitalPromotions13 from './../images/works/Digital Promotions/60418780_1887594244677733_5078223228887891968_o.jpg'
import DigitalPromotions14 from './../images/works/Digital Promotions/60461685_1887590578011433_721032989324083200_o.png'
import DigitalPromotions15 from './../images/works/Digital Promotions/66088766_1333038250178912_573418803061325824_o.jpg'
import DigitalPromotions16 from './../images/works/Digital Promotions/67563735_2017782521658904_3460521872704143360_o.png'
import DigitalPromotions17 from './../images/works/Digital Promotions/67697557_665215883965031_1775289135112650752_o.jpg'
import DigitalPromotions18 from './../images/works/Digital Promotions/67828930_663352584151361_3981007518829641728_o.jpg'
import DigitalPromotions19 from './../images/works/Digital Promotions/74325825_2147333802037108_2032018503128580096_o.png'
import DigitalPromotions20 from './../images/works/Digital Promotions/74456377_2628030093915335_4662854772733247488_o.png'
import DigitalPromotions21 from './../images/works/Digital Promotions/74664501_2624100607641617_1133167601392812032_o.png'
import DigitalPromotions22 from './../images/works/Digital Promotions/Bastille-Day-18.jpg'
import DigitalPromotions23 from './../images/works/Digital Promotions/Canvas_508-01.jpg'
import DigitalPromotions24 from './../images/works/Digital Promotions/Canvas_508-02.jpg'
import DigitalPromotions25 from './../images/works/Digital Promotions/Canvas_508-04.jpg'
import DigitalPromotions26 from './../images/works/Digital Promotions/Canvas_508-05.jpg'
import DigitalPromotions27 from './../images/works/Digital Promotions/Canvas_508-06.jpg'
import DigitalPromotions28 from './../images/works/Digital Promotions/Canvas_508-07.jpg'
import DigitalPromotions29 from './../images/works/Digital Promotions/Digital_01.jpg'
import DigitalPromotions30 from './../images/works/Digital Promotions/e-Eid-Card.jpg'
import DigitalPromotions31 from './../images/works/Digital Promotions/IDLC---Eid-Card-Design_Final.png'
import DigitalPromotions32 from './../images/works/Digital Promotions/Layout--5.jpg'
import DigitalPromotions33 from './../images/works/Digital Promotions/Post_USP_16.07.18.jpg'
import DigitalPromotions34 from './../images/works/Digital Promotions/Vigo - Multi-Cooker_Boishakh.png'

const DigitalPromotions = [DigitalPromotions1, DigitalPromotions2, DigitalPromotions3, DigitalPromotions4, DigitalPromotions5, DigitalPromotions6, DigitalPromotions7, DigitalPromotions8, DigitalPromotions9, DigitalPromotions10, DigitalPromotions11, DigitalPromotions12, DigitalPromotions13, DigitalPromotions14, DigitalPromotions15, DigitalPromotions16, DigitalPromotions17, DigitalPromotions18, DigitalPromotions19, DigitalPromotions20, DigitalPromotions21, DigitalPromotions22, DigitalPromotions23, DigitalPromotions24, DigitalPromotions25, DigitalPromotions26, DigitalPromotions27, DigitalPromotions28, DigitalPromotions29, DigitalPromotions30, DigitalPromotions31, DigitalPromotions32, DigitalPromotions33, DigitalPromotions34]

export default DigitalPromotions;