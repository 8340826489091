import LogoDesign1 from './../images/works/Logo Design/640X360_Bng.a.jpg'
import LogoDesign2 from './../images/works/Logo Design/640X360_Eng.a.jpg'
import LogoDesign3 from './../images/works/Logo Design/BM_New_04.b.2.jpg'
import LogoDesign4 from './../images/works/Logo Design/Club Royal Logo with tagline-01.jpg'
import LogoDesign5 from './../images/works/Logo Design/CrossCut Logo-11.jpg'
import LogoDesign6 from './../images/works/Logo Design/Ketly-Logo_Final-Output_Normal.jpg'
import LogoDesign7 from './../images/works/Logo Design/Ketly-Logo_Final-Output_Reverse.jpg'
import LogoDesign8 from './../images/works/Logo Design/Turkey-logo_06.jpg'
import LogoDesign9 from './../images/works/Logo Design/Walker_1_Logo.jpg'

const LogoDesign = [LogoDesign1, LogoDesign2, LogoDesign3, LogoDesign4, LogoDesign5, LogoDesign6, LogoDesign7, LogoDesign8, LogoDesign9]

export default LogoDesign