import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'

import './LiveChat.css'

const LiveChat = () => {
    return (
        <div>
            {/* hello */}
            <MessengerCustomerChat
                // Page Id: 108052024403278
                // App Id: 1066557250452757
                pageId={108052024403278}
                appId={1066557250452757}
            // htmlRef="<REF_STRING>"
            />
        </div>
    )
}

export default LiveChat