import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { useAlert } from "react-alert";

import { useForm } from 'react-hook-form';

import './Contact.css'

const Contact = () => {
    const alert = useAlert();

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data, e) => {

        emailjs.sendForm('gmail', 'template_1f2cxjm', e.target, 'user_GfGDGhAl7v6hxLLA132Us')
            .then((result) => {
                // setAlertShow(true)
                alert.success("Form Submitted Successfully!");
                // console.log(result.text);
            }, (error) => {
                // console.log(error.text);
                alert.error("Something Wrong!");
            });
        e.target.reset()
    };
    return (
        <Container id="contact">
            <Row className="my-4">
                <Col md={12}>
                    <div className="main-t">
                        <h1 id="contact-text">
                            <span style={{ color: '#FFC10E' }}> Communication</span><br />
                        works for those<br />
                        who
                        <span style={{ color: '#FFC10E' }}> work</span> at it.
                    </h1>
                        <h4 id="john"> - John Powell</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div id="left-contact">
                        <h2 className="left-h2"> <span style={{ color: '#FFC10E' }}> hello!</span><br /> </h2>
                        <p >
                            We want to hear from you. <br />
                            Feel free to stay in touch. <br />
                            Share your ideas, thoughts or <br />
                            <span style={{ marginLeft: '-20px' }}>opportunities to create your Brand</span>
                        </p>
                        <h5> <span style={{ color: '#FFC10E' }}>Buzzworthy.</span><br /> </h5>
                    </div>
                </Col>
                <Col style={{ marginBottom: '4rem' }} md={6}>
                    <div>
                        <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
                            <div className="">
                                <input name="name" className="mr-2" ref={register({ required: true })} placeholder="Please Enter Your Name" />
                                {errors.name && <li style={{ color: '#FFC10E' }}>Please Enter Your Name</li>}<br />

                                <input
                                    name="email"
                                    ref={register({
                                        required: true,
                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })}
                                    placeholder="Please Enter Your Phone Email"
                                />
                                {errors.email && <li style={{ color: '#FFC10E' }}>Please Enter Your Valid Email!</li>} <br />

                                <textarea rows="4" cols="50" name="message" ref={register({ required: true })} placeholder="Your Thoughts!"></textarea>
                                {errors.message && <li style={{ color: '#FFC10E' }}>Please Enter Your Thoughts</li>} <br />
                                <input
                                    // onClick={() => {
                                    //     alert.success("Form Submitted Successfully!");
                                    // }}
                                    id="contactBtn"
                                    value="Send Over"
                                    type="submit"
                                />
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;